import React, { Fragment } from "react";
import {
  InLocationsItems,
  InTariff,
} from "../../../../../../fetch/types/locations.types";
import {
  Form,
  Label,
  ModalContent,
  ModalHeader,
  Segment,
  Tab,
  TabPane,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { MOCK_TYPE_CONNECTOR_CHARGERS } from "../../../../homeConstant";
import Map from "../../maps";
import { daysOfWeek } from "./modalSchedule";
import moment from "moment";
import useSessionStore from "../../../../../../store/useSession";
import { ROLES_NAME } from "../../../../../../shared/constants";
export interface InRegularHours {
  weekday: number;
  hours: {
    period_begin: string;
    period_end: string;
  }[];
}

const ModalInformationOPC: React.FC<{
  folio: InLocationsItems;
}> = ({ folio }) => {
  const { profile } = useSessionStore();

  const getRenderTable = () => {
    const newArray: InRegularHours[] = [];
    if (!folio.opening_times?.regular_hours) return null;

    folio.opening_times.regular_hours.forEach((hours) => {
      const existingDay = newArray.find(
        (item: any) => item.weekday === hours.weekday
      );

      if (existingDay) {
        existingDay.hours.push({
          period_begin: hours.period_begin,
          period_end: hours.period_end,
        });
      } else {
        newArray.push({
          weekday: hours.weekday,
          hours: [
            {
              period_begin: hours.period_begin,
              period_end: hours.period_end,
            },
          ],
        });
      }
    });

    const rows = newArray.map((item) => (
      <Table.Row key={item.weekday}>
        <Table.Cell>{daysOfWeek[item.weekday - 1]}</Table.Cell>
        <Table.Cell>
          {item.hours.map((hour, index) => (
            <div key={index}>
              {hour.period_begin} - {hour.period_end}
            </div>
          ))}
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Día de la semana</Table.HeaderCell>
            <Table.HeaderCell>Horas de trabajo</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };

  const getChargers = () => {
    return folio.evses.map(
      (
        {
          evse_uid,
          status,
          floor_level,
          order_number: order,
          brand,
          physical_reference,
          model,
          max_electric_power,
          uso_exclusivo,
          permite_carga_simultanea,
          activation_capabilities,
          payment_capabilities,
          directions,
          last_updated,
        },
        index
      ) => {
        return {
          menuItem: {
            key: `charger_tab-${index}`,
            icon: "plug",
            content: `Cargador ${evse_uid} (${evse_uid})`,
          },
          render: () => (
            <TabPane className="px-0" style={{ border: "none" }} key={evse_uid}>
              <Form.Group widths="3">
                <Form.Input label="Orden Cargador" value={order} />
                <Form.Input label="Estado" value={status} />
                <Form.Input label="Nivel" value={floor_level || "---"} />
              </Form.Group>
              <Form.Group widths="3">
                <Form.Input
                  label="Referencia Fisica"
                  value={physical_reference || "---"}
                />
                <Form.Input
                  label="Capacidades de Pago"
                  value={payment_capabilities[0] || "No definido"}
                />
                <Form.Input
                  label="Capacidades de activación"
                  value={activation_capabilities[0] || "No definido"}
                />
              </Form.Group>

              <Form.Group widths="3">
                <Form.Input label="Modelo" value={model} />
                <Form.Input label="Marca" value={brand} />
                <Form.Input
                  label="Max. Potencia"
                  value={max_electric_power}
                  type="number"
                />
              </Form.Group>

              <Form.Input
                label="Coordenadas"
                value={`${folio.coordinates.latitude}, ${folio.coordinates.longitude}`}
              />

              <Form.Group widths="equal">
                <Form.Checkbox
                  label="Permite carga simultanea"
                  checked={permite_carga_simultanea || false}
                />
                <Form.Checkbox
                  label="Dominio Privado"
                  checked={uso_exclusivo || false}
                />
              </Form.Group>

              <div className="d-flex justify-between mx-2 mb-2">
                <span className="text-bold">
                  Ultima Actualización:
                  <span className="text-bold ml-2">
                    {moment(last_updated).format("DD MMMM YYYY HH:MM")}
                  </span>
                </span>
              </div>

              <Form.TextArea
                label="Instrucciones para el cargador"
                value={directions || "---"}
              />
            </TabPane>
          ),
        };
      }
    );
  };

  const getRenderMyTariff = (tariffs: InTariff[]) => {
    if (!profile)
      return (
        <Label
          basic
          className="px-0 mb-1 text-center"
          size="large"
          color="black"
        >
          No Existen Tarifas a Mostrar
        </Label>
      );

    const allTariffs = tariffs.filter((item) => item.pse_id === profile.userId);
    if (allTariffs.length < 1)
      return (
        <Label
          basic
          className="px-0 mb-1 text-center"
          size="large"
          color="black"
        >
          No Existen Tarifas a Mostrar
        </Label>
      );

    return allTariffs.map((t) => (
      <Fragment key={t.tariff_id}>
        <Form.Group widths={"2"}>
          <Form.Input label="Precio minimo" value={t.min_price}></Form.Input>
          <Form.Input label="Precio  máximo " value={t.max_price}></Form.Input>
        </Form.Group>
        <Label basic size="medium" color="black">
          Componentes
        </Label>
        <Form.Group>
          {t.elements?.price_components &&
            t.elements.price_components.length > 0 && (
              <Table basic collapsing celled className="ml-2 mb-2">
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Dimension</TableHeaderCell>
                    <TableHeaderCell>Precio</TableHeaderCell>
                    {/* <TableHeaderCell>Unidad de Cobro</TableHeaderCell> */}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {t.elements.price_components.length > 0 ? (
                    t.elements.price_components.map((components) => (
                      <TableRow key={components.order_number}>
                        <TableCell>{components.tariff_dimension}</TableCell>
                        <TableCell>{components.price}</TableCell>
                        {/* <TableCell>{components.step_size}</TableCell> */}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key={Date.now()}>
                      <TableCell className="text-bold" colspan="3">
                        No existen datos en Dimensions
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
        </Form.Group>
      </Fragment>
    ));
  };

  const getConnectors = () => {
    const connectors =
      folio.evses.find((item) => item.evse_uid === 1)?.connectors ||
      folio.evses[0].connectors;

    return connectors.map(
      (
        {
          connector_id,
          standard,
          format,
          voltage,
          status,
          max_voltage,
          amperage,
          max_amperage,
          electric_power,
          max_electric_power,
          soc,
          order_number,
          last_updated,
          tariffs,
          power_type
        },
        index
      ) => {
        return {
          menuItem: {
            key: `connector ${index}`,
            icon: "plug",
            content: `Conector ${index + 1} (${connector_id})`,
          },
          render: () => (
            <TabPane className="px-0" style={{ border: "none" }} key={index}>
              <Form.Group widths="2">
                <Form.Input
                  label="Orden Conector"
                  value={order_number}
                  placeholder="Orden Conector"
                />

                <Form.Input label="SOC" value={soc} placeholder={"SOC"} />
              </Form.Group>
              <Form.Group widths="2">
                <Form.Input
                  label="Tipo Conector"
                  value={MOCK_TYPE_CONNECTOR_CHARGERS[standard] ?? standard}
                  placeholder={"Tipo Conector"}
                />
                <Form.Input
                  label="Formato"
                  value={format}
                  placeholder="Formato"
                />
              </Form.Group>

              <Form.Group widths="3">
                {/* <Form.Input
                  label="Voltaje"
                  value={voltage}
                  placeholder="Voltaje"
                  type="number"
                  min={0}
                /> */}
                <Form.Input
                  label="Voltaje Máximo"
                  value={max_voltage}
                  placeholder="Voltaje Máximo"
                  type="number"
                  min={0}
                />
                {/* <Form.Input
                  label="Amperaje"
                  value={amperage}
                  placeholder={"Amperaje "}
                  type="number"
                  min={0}
                /> */}
                <Form.Input
                  label="Amperaje Máximo"
                  value={max_amperage}
                  placeholder={"Amperaje Máximo "}
                  type="number"
                  min={0}
                />
                <Form.Input
                  label="Tipo de potencia"
                  value={power_type}
                  placeholder={"Tipo de potencia"}
                  type="string"
                  disabled={true}
                  min={0}
                />
              </Form.Group>
              <Form.Group widths="3">

                <Form.Input
                  label="Potencia"
                  value={electric_power}
                  placeholder="Potencia"
                  type="number"
                  min={0}
                />
                <Form.Input
                  label="Max. Potencia"
                  value={max_electric_power}
                  placeholder={"Max. Potencia"}
                  type="number"
                  min={0}
                />
                <Form.Input
                  label="Estado"
                  placeholder="Estado"
                  value={status}
                />
              </Form.Group>
              {profile?.currentRole?.nameRol === ROLES_NAME.PSE && (
                <div className="d-flex flex-column">
                  <Label className="px-0" basic size="large" color="blue">
                    Datos de tarifas
                  </Label>

                  {tariffs.length > 0 ? (
                    getRenderMyTariff(tariffs)
                  ) : (
                    <Label
                      basic
                      className="px-0 mb-1 text-center"
                      size="large"
                      color="black"
                    >
                      No Existen Tarifas a Mostrar
                    </Label>
                  )}
                </div>
              )}
              <div className="d-flex justify-between mx-2 mb-2">
                <span className="text-bold">
                  Ultima Actualización:
                  <span className="text-bold ml-2">
                    {moment(last_updated).format("DD MMMM YYYY HH:MM")}
                  </span>
                </span>
              </div>
            </TabPane>
          ),
        };
      }
    );
  };

  const panes = [
    {
      menuItem: "Información General",
      render: () => {
        return (
          <TabPane>
            <Form>
              <Form.Group widths={"2"}>
                <Form.Input label="Nombre Folio" value={folio.name} />
                <Form.Input label="ID Folio" value={folio.location_id} />
              </Form.Group>
              <Form.Group widths={"3"}>
                <Form.Input
                  label="Tipo Estacionamiento"
                  value={folio.parking_type}
                />
                <Form.Input label="Zona Horaria" value={folio.time_zone} />
                <Form.Input
                  label="Número Soporte"
                  value={folio.support_phone_number || "---"}
                />
              </Form.Group>

              <Form.Group widths={"2"}>
                <Form.Checkbox
                  label="Atención 24/7"
                  checked={folio.opening_times?.twentyfourseven}
                />
                <Form.Checkbox
                  label="Accesible fuera de horario"
                  checked={folio.charging_when_closed}
                />
              </Form.Group>
              <Form.Group widths={"3"}>
                <Form.Input
                  label="Propietario"
                  value={folio.owner?.name || "---"}
                />
                <Form.Input label="OPC" value={folio.OPC?.name || "---"} />
                <Form.Input
                  label="PSE"
                  value={
                    folio.PSEs && folio.PSEs?.length >= 1
                      ? `(${folio.PSEs.length}) PSE Designado(s)`
                      : `---`
                  }
                />
              </Form.Group>
              <Form.Group widths={"3"}>
                <Form.Input label="Dirección" value={folio.address} />
                <Form.Input label="Region" value={folio.region} />
                <Form.Input label="Comuna" value={folio.commune} />
              </Form.Group>

              <Form.TextArea
                label="Indicaciones"
                value={folio.directions || "---"}
              />

              <label className="text-bold">Coordenadas</label>
              <Segment placeholder>
                <Map
                  latitude={Number(folio.coordinates.latitude)}
                  longitude={Number(folio.coordinates.longitude)}
                  name={folio.name}
                />
              </Segment>
              <div className="d-flex justify-between mx-2">
                <span className="text-bold">
                  Fecha Revisión:
                  <span className="ml-2 text-bold">
                    {moment(folio.revision_date).format(
                      "DD-MMMM-YYYY HH:mm:ss"
                    )}
                  </span>
                </span>

                <span className="text-bold">
                  Tipo de instalación:
                  <span className="ml-2 text-bold">
                    {folio.charging_instalation_type || "---"}
                  </span>
                </span>
              </div>
            </Form>
          </TabPane>
        );
      },
    },

    {
      menuItem: "Información Cargadores",
      render: () => (
        <TabPane>
          <Form className="form-tab-content">
            {folio.evses.length > 0 ? (
              <Tab panes={getChargers()} />
            ) : (
              <Label basic className="px-0 mb-1" size="large" color="blue">
                No existen cargadores
              </Label>
            )}

            <Tab panes={getConnectors()} />
          </Form>
        </TabPane>
      ),
    },
    {
      menuItem: "Información Horario",

      render: () => getRenderTable(),
    },
  ];

  return (
    <Fragment>
      <ModalHeader content={`Información  IRVE - ${folio.folio_IRVE}`} />
      <ModalContent content={<Tab panes={panes} />} />
    </Fragment>
  );
};

export default ModalInformationOPC;
